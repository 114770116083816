import { Component } from 'react';

import { OverviewCard } from './OverviewSet';

import * as style from './Section.module.less';

export class SectionTwo extends Component {
    renderCards = () => (
        <div className="d-flex flex-sm-row flex-column flex-wrap align-items-center justify-content-center gap-4">
            <OverviewCard title="High Performance">
                The use of WASM enhances the execution speed, ensuring that the
                randomness generation is swift even with multiple participants.
            </OverviewCard>
            <OverviewCard title="Resistance to Manipulation ">
                The use of WASM enhances the execution speed, ensuring that the
                randomness generation is swift even with multiple participants.
            </OverviewCard>
            <OverviewCard title="Scalability">
                The use of WASM enhances the execution speed, ensuring that the
                randomness generation is swift even with multiple participants.
            </OverviewCard>
            <OverviewCard title="Verifiable Proofs">
                The use of WASM enhances the execution speed, ensuring that the
                randomness generation is swift even with multiple participants.
            </OverviewCard>
        </div>
    );

    render() {
        return (
            <div className="d-flex flex-column align-items-center gap-5 bg-black-a p-5">
                <h3
                    className={`font-monument text-uppercase mb-4 text-center ${style['main-title']}`}
                >
                    Advantages of zkVRF to other service
                </h3>

                {this.renderCards()}
            </div>
        );
    }
}
