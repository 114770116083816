import classNames from 'classnames';
import { Icon } from 'idea-react';
import { observable } from 'mobx';
import { useObserver } from 'mobx-react';
import * as React from 'react';
import { createPortal } from 'react-dom';

interface ToastConfig {
    key?: string;
    className?: string;
    content?: React.ReactNode;
    duration?: number;
    error?: boolean;
}

export const toastList = observable(new Map<string, ToastConfig>());

export const MToastContainer = () =>
    useObserver(() => {
        const list = [...toastList.values()];

        return createPortal(
            list.length ? (
                <div className="global-toast-layer">
                    {list.map(({ key, content, error, className }) => (
                        <div
                            className={classNames(
                                'global-toast-item d-flex align-items-center',
                                className,
                                error && 'global-toast-item-error'
                            )}
                            key={key}
                        >
                            <Icon
                                name={
                                    error
                                        ? 'exclamation-octagon'
                                        : 'info-circle'
                                }
                                className={classNames(
                                    'me-3',
                                    error
                                        ? 'global-toast-item-error'
                                        : 'text-white'
                                )}
                            />
                            {content}
                        </div>
                    ))}
                </div>
            ) : null,
            document.body
        );
    });

export function toast(config: ToastConfig) {
    const conf = {
        ...config,
        key: new Date().toJSON(),
        duration: config.duration || 3000
    };
    toastList.set(conf.key, conf);
    const remove = () => {
        toastList.delete(conf.key);
    };
    if (conf.duration) {
        setTimeout(remove, conf.duration);
    }
    return remove;
}

export function hanldeError(err: any, config?: ToastConfig) {
    const message = !err
        ? 'unknown error'
        : err?.message
          ? err.message
          : typeof err === 'string'
            ? err
            : JSON.stringify(err);
    return toast({
        ...config,
        content: message,
        error: true
    });
}
