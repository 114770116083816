import { toast } from "../component/Toast";

export const formatAddress = (addr: string) => {
    const upperAfterLastTwo = addr.slice(0, 2) + addr.slice(2);

    return `${upperAfterLastTwo.substring(0, 5)}...${upperAfterLastTwo.substring(39)}`;
};


export const copy = async (value: string, tips: string) => {
    try {
        await navigator.clipboard.writeText(value);
        toast({ content: tips });
    } catch (error) {
        console.log(error);
    }
};