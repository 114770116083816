import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ContentItem } from './OverviewSet';
import * as style from './Section.module.less';

export const barChart = new URL('../image/bar-chart.jpg', import.meta.url) + '';

export class SectionFour extends Component {
    renderCards() {
        return (
            <Row
                className="border-start border-dark pt-4 font-poppins d-flex align-items-center flex-wrap m-0"
                md={1}
                lg={2}
            >
                <Col as="dl" className="m-0 p-0">
                    <ContentItem title="Signer">
                        Signing nodes participate in the network to sign the
                        seed numbers registered by users.
                    </ContentItem>
                    <ContentItem title="Groups">
                        Groups participating in the network coordinate a certain
                        number of signing nodes to provide seed signatures and
                        maintain the group's liveness.
                    </ContentItem>
                    <ContentItem title="Random number provider">
                        The provider is a blockchain contract offering an API
                        for users to register their seed and supply random
                        numbers with zero-knowledge proof.
                    </ContentItem>
                </Col>
                <Col as="img" className="object-fit m-0 p-0" src={barChart} />
            </Row>
        );
    }

    render() {
        return (
            <div className="d-flex flex-column align-items-start bg-black-a px-sm-10">
                <h3
                    className={`ps-3 font-monument text-uppercase text-start border-bottom border-start border-dark mb-0 ${style['main-title']}`}
                >
                    Highly effective solutions
                </h3>

                {this.renderCards()}
            </div>
        );
    }
}
