import { SectionFour } from '../component/SectionFour';
import { SectionOne } from '../component/SectionOne';
import { SectionThree } from '../component/SectionThree';
import { SectionTwo } from '../component/SectionTwo';
import { SectionZero } from '../component/SectionZero';

import * as style from './Home.module.less';

export const zkvrfURL = new URL('../image/zkvrf.mp4', import.meta.url) + '';

export const HomePage = () => (
    <>
        <video
            className={`vw-100 vh-100  ${style['full-video']}`}
            src={zkvrfURL}
            autoPlay
            muted
            loop
        />

        <div className={`${style['video-mask']}`} />

        <div className={`m-0 p-0 ${style['full-scroll']}`}>
            <SectionZero />
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
        </div>
    </>
);
