import { action, computed, observable } from 'mobx';
import { ResponseData, ZkVRFBase } from './service';
import { toggle } from 'mobx-restful';

// Update when backend is ready
export interface Group {
    grouphash: string;
    groupid:string;
}

export interface GroupResponse {
    status: number;
    message: string;
}

export class GroupModel extends ZkVRFBase {
    @observable
    accessor pageList: Group[] = [];

    @observable
    accessor currentOne: Group;

    @computed
    get totalCount() {
        return this.pageList.length;
    }

    @action
    @toggle('downloading')
    async getList() {
        const { body } =
            await this.client.get<ResponseData<Group[]>>('allgroups');

        this.pageList = body.data;
    }

    @toggle('uploading')
    join(grouphash: string, nodehash: string) {
        return this.client.post<GroupResponse>('joingroup', {
            grouphash,
            nodehash
        });
    }

    @action
    async getOne(grouphash: string) {
        const { body } = await this.client.post<Group>('getgroup', {
            grouphash
        });

        return (this.currentOne = body);
    }
}

export default new GroupModel();
