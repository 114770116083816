import { HashRouter, Route, Routes } from 'react-router-dom';

import { Footer } from '../component/Footer';
import { MainNavigator } from '../component/MainNavigator';
import { MToastContainer } from '../component/Toast';
import { HomePage } from './Home';

export const PageFrame = () => (
    <>
        <MainNavigator />

        <HashRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </HashRouter>
        <MToastContainer />
        <Footer />
    </>
);
