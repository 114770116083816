import { observer } from 'mobx-react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { Component } from 'react';
import { Icon } from 'idea-react';

import ethersModel from '../model/ethers';
import { formatAddress } from '../model/utility';

import { OverviewData } from './OverviewSet';
import * as style from './MainNavigator.module.less';
import { toast } from './Toast';

@observer
export class MainNavigator extends Component {
    handleConnect = async () => {
        try {
            await ethersModel.init();
        } catch {
            return toast({
                error: true,
                content: 'User reject the operation!'
            });
        }
        ethersModel.getAddress();
    };

    renderUserInfo() {
        return (
            <div className="bg-black-a p-4 position-absolute top-100 end-0 d-none rounded-3">
                <OverviewData title="Joined Group" data="3" />
                <OverviewData title="Signed Times" data="3" />
                <OverviewData title="Minted Points" data="2" />
                <OverviewData title="Shared Fees" data="3" />
            </div>
        );
    }

    render() {
        const { address } = ethersModel;

        return (
            <Navbar
                style={{ zIndex: 1030 }}
                className={`position-absolute mt-5 py-2 ps-5 pe-2 d-flex flex-unwrap align-items-center justify-content-between ${style['navbar-bg']}`}
                expand="lg"
                variant="dark"
                collapseOnSelect
            >
                <Nav className="d-flex gap-4 flex-row align-items-center text-grey-a">
                    <Nav.Link>APP</Nav.Link>
                    <Nav.Link href="https://docs.zkcross.org/developer-guide/zkvrf-integration-guide" target="_blank">Docs</Nav.Link>
                </Nav>

                <Navbar.Brand className="px-5 fs-28 text-center" href=".">
                    zkVRF
                </Navbar.Brand>

                {address ? (
                    <div className={`position-relative ${style['user-info']}`}>
                        <Button
                            className="px-3 bg-gradient-a border border-grey-b"
                            variant="dark"
                        >
                            {formatAddress(address)}
                            <Icon className="ps-3" name="chevron-down" />
                        </Button>
                        {this.renderUserInfo()}
                    </div>
                ) : (
                    <Button
                        className="px-3 bg-gradient-a border border-grey-b"
                        variant="dark"
                        onClick={this.handleConnect}
                    >
                        Connect+
                    </Button>
                )}
            </Navbar>
        );
    }
}
