import { Component } from 'react';

import { SectionCard } from './OverviewSet';

import * as style from './SectionThree.module.less';

export class SectionThree extends Component {
    renderCards() {
        return (
            <ul className={`list-unstyled d-flex mb-0 ${style['list-box']}`}>
                <SectionCard icon="graph-up-arrow" title="Validator selection">
                    Used to select validators or committees in PoS systems to
                    ensure fair and secure elections.
                </SectionCard>
                <SectionCard icon="controller" title="On-chain game">
                    Ensures the fairness and verifiability of random outcomes in
                    decentralized applications.
                </SectionCard>
                <SectionCard icon="pie-chart" title="Random Beacons">
                    Used to select validators or committees in PoS systems to
                    ensure fair and secure elections.
                </SectionCard>
                <SectionCard icon="bag" title="Commitment Schemes">
                    Ensures the fairness and verifiability of random outcomes in
                    decentralized applications.
                </SectionCard>
            </ul>
        );
    }

    render() {
        return (
            <div className="bg-black-a py-5">
                <div
                    className={`${style['rounded-box']} pt-5 d-flex flex-column align-items-end gap-0`}
                >
                    <h3
                        className={`${style['section-header']} font-monument fw-400 align-self-start w-100 mb-0`}
                    >
                        use cases
                    </h3>

                    {this.renderCards()}
                </div>
            </div>
        );
    }
}
