.navbar-bg {
    border-radius: 0.625rem;
    border: 1px solid rgba(247, 248, 248, 0.3);
    background: rgba(34, 34, 34, 0.8) !important;
    box-shadow: 0px 4px 13.4px 0px rgba(0, 81, 57, 0.12);
    backdrop-filter: blur(36px);

    @media (max-width: 768px) {
        width: 100%;
        margin: 2.25rem 0 0 0;
    }
    margin: 2.25rem 5% 0 5%;
    width: 90%;
}

.user-info:hover div {
    display: flex !important;
}
