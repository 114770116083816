import { observer } from 'mobx-react';
import { Component } from 'react';
import { Button, Card, OverlayTrigger } from 'react-bootstrap';

import { OverviewData } from './OverviewSet';

import groupModel from '../model/group';

import * as style from './Section.module.less';
import { Icon, OverlayBox } from 'idea-react';
import { copy } from '../model/utility';

@observer
export class SectionOne extends Component {
    componentDidMount() {
        groupModel.getList();
    }

    renderStatistical(totalCount: number) {
        return (
            <ul className="gradient-list d-flex flex-wrap gap-4 justify-content-center align-items-center">
                <OverviewData title="Total Requests +" data="106758443" />
                <OverviewData title="Pending Request +" data="106758443" />
                <OverviewData title="Signing Groups +" data={totalCount + ''} />
                <OverviewData title="Signing Nodes + " data="106758443" />
            </ul>
        );
    }

    renderColumns({ grouphash, groupid }) {
        return (
            <li key={grouphash} className="d-flex gap-1 align-items-center">
                <OverlayTrigger
                    trigger="click"
                    placement="bottom-end"
                    rootClose // Added to close the overlay on outside click or Esc key
                    overlay={
                        <dl className="d-flex flex-column p-3 gap-3 bg-gradient-c rounded-3 shadow-a blur-1">
                            <dt className="text-white">groupid</dt>
                            <dd className="bg-grey-d p-2 text-grey-e rounded-1">
                                {groupid}
                            </dd>

                            <dt className="text-white">
                                grouphash
                                <Icon
                                    size={0.6}
                                    name="copy"
                                    className="ps-2"
                                    onClick={() => copy(grouphash, 'Copied!')}
                                />
                            </dt>
                            <dd className="bg-grey-d p-2 text-grey-e rounded-1">
                                {grouphash}
                            </dd>
                        </dl>
                    }
                >
                    <Button
                        variant="dark"
                        className="bg-gradient-b border border-grey-c rounded-1 px-3 col-8 text-grey-f font-poppins"
                    >
                        {grouphash.substring(0, 15)}
                    </Button>
                </OverlayTrigger>

                <Button
                    variant="dark"
                    className="bg-gradient-b border border-grey-c rounded-1 px-3 col-2 text-grey-f font-poppins"
                >
                    531
                </Button>
                <Button
                    variant="dark"
                    className="bg-gradient-b border border-grey-c rounded-1 px-3 col-2 text-grey-f font-poppins"
                >
                    1
                </Button>
            </li>
        );
    }

    render() {
        const { totalCount, pageList } = groupModel;

        return (
            <div className="d-flex flex-column align-items-center gap-5 bg-black-a pt-5 px-5 py-4">
                <h3
                    className={`font-monument text-uppercase mb-4 text-center ${style['main-title']}`}
                >
                    zkVRF network live data
                </h3>
                {this.renderStatistical(totalCount)}
                <h4 className="text-center text-white mb-3">
                    Group Details
                </h4>{' '}
                {/* Add title here */}
                <ul className="list-unstyled d-flex flex-column gap-3 w-75">
                    <li className="d-flex gap-1 align-items-center w-100">
                        {' '}
                        {/* Added header row */}
                        <span className="col-8 text-center text-white">
                            Groups (Click to display grouphash)
                        </span>
                        <span className="col-2 text-center text-white">
                            Group signing times
                        </span>
                        <span className="col-2 text-center text-white">
                            Group signing nodes
                        </span>
                    </li>
                    {pageList.map(this.renderColumns)}
                </ul>
            </div>
        );
    }
}

