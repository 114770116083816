import { Icon } from 'idea-react';

export type MediaLink = Record<'icon' | 'link', string>;

export const mediaLinks = [
    { icon: 'twitter-x', link: 'https://x.com/thezkcross' },
    { icon: 'discord', link: 'https://discord.com/invite/aJNjfRvgam' },
    { icon: 'telegram', link: 'https://t.me/ZKCross' },
    { icon: 'medium', link: 'https://blog.zkcross.org/' }
];

export const Footer = () => (
    <footer className="border-top border-white d-flex align-items-center justify-content-between bg-black-a p-5 flex-column flex-md-row">
        <nav className="d-flex gap-3 font-poppins align-items-center">
            <span className="text-white fs-28 text-capitalize">zkVRF</span>
            {mediaLinks.map(({ icon, link }) => (
                <a
                    className="text-white fs-18"
                    target="_blank"
                    href={link}
                    key={link}
                >
                    <Icon name={icon} />
                </a>
            ))}
        </nav>

        <div className="font-space text-white">
            Copyright © 2024 ZKVRF | All Rights Reserved by ZKCross |
        </div>
    </footer>
);
