import { HTTPClient } from 'koajax';
import { BaseModel } from 'mobx-restful';

export type TokenData = Record<'access_token' | 'token_type', string>;

export interface ResponseData<T> {
    data: T;
}

export const zkVRFClient = new HTTPClient({
    baseURI: 'https://rpc.zkvrf.xyz/',
    responseType: 'json'
}).use(async ({ request }, next) => {
    request.headers = {
        ['Authorization']: `Bearer ${localStorage.access_token}`,
        ...request.headers
    };
    return next();
});

export class ZkVRFBase extends BaseModel {
    client = zkVRFClient;

    constructor() {
        super();
        this.getToken();
    }

    async getToken(client_id = 'user1', client_secret = 'password1') {
        if (localStorage.access_token) return localStorage.access_token;

        const {
            body: { access_token }
        } = await this.client.post<TokenData>('authorize', {
            client_id,
            client_secret
        });

        return (localStorage.access_token = access_token);
    }
}
