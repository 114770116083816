.gradient-data {
    font-feature-settings:
        'clig' off,
        'liga' off;
    font-family: 'Core Sans D 55 Bold';
    font-size: 53px;
    line-height: 2.5rem; /* 75.472% */
    background: linear-gradient(87deg, #fff -46.12%, #25f198 255.23%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.overview-card {
    align-self: stretch;
    border-radius: 1.125rem !important;
    border: 1px solid rgba(247, 248, 248, 0.21) !important;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.01) 100%
    ) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
    max-width: max(40%, 500px);
}

.overview-title {
    font-feature-settings:
        'clig' off,
        'liga' off;
    line-height: 2.375rem; /* 211.111% */
    color: rgba(247, 247, 247, 0.68) !important;
}

.overview-body {
    color: rgba(165, 178, 172, 0.51);
    font-feature-settings:
        'clig' off,
        'liga' off;

    line-height: 140%; /* 19.6px */
}

.section-title {
    font-feature-settings:
        'clig' off,
        'liga' off;

    line-height: 31px; /* 134.783% */
    display: grid;
    grid-template-rows: 2.5rem 3.75rem;

    @media (max-width: 768px) {
        grid-template-rows: 2.5rem 2.5rem;
    }
}

.section-body {
    font-feature-settings:
        'clig' off,
        'liga' off;

    line-height: 1.5rem; /* 150% */
}

.dt-title {
    font-feature-settings:
        'clig' off,
        'liga' off;
    color: rgba(247, 247, 247, 0.68) !important;
    border-left: 1.5px solid #25f198;
}

.dd-body {
    color: rgba(255, 255, 255, 0.55) !important;
    font-feature-settings:
        'clig' off,
        'liga' off;
    line-height: 1.5rem; /* 150% */
}
