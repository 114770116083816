.main-title {
    color: #fff;
    font-feature-settings:
        'clig' off,
        'liga' off;
    text-shadow: 0px 4px 24.6px rgba(255, 255, 255, 0.39) !important;
    font-family: 'Monument Extended';
    font-size: 3rem;
    font-weight: 400;
    line-height: 82px; /* 170.833% */
    letter-spacing: 1.92px;
    text-transform: uppercase;
}

.ellipse-1 {
    width: 1200px;
    height: 905px;
    flex-shrink: 0;
    border-radius: 1200px;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(194, 97, 254, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}
