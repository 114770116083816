import { Icon } from 'idea-react';
import { Button, Container } from 'react-bootstrap';

import * as style from './SectionZero.module.less';

export const SectionZero = () => (
    <Container className="vh-100 d-flex flex-column gap-5 align-items-center justify-content-center">
        <h2
            className={`${style['zkvrf-header']} font-monument fw-400 text-uppercase text-center text-break`}
        >
            verifiable random number generated with zkp
        </h2>
        <Button
            className="px-3 bg-gradient-a border border-grey-b font-poppins"
            variant="dark"
        >
            Get started <Icon name="arrow-right" />
        </Button>
    </Container>
);
