import { BrowserProvider, getDefaultProvider, JsonRpcSigner } from 'ethers';
import { action, observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';

export class EthersModel extends BaseModel {
    @observable accessor provider =
        getDefaultProvider() as unknown as BrowserProvider;

    @observable accessor signer: JsonRpcSigner;

    @observable accessor address = localStorage.eth_address;

    async init() {
        if (!window.ethereum) return console.error('MetaMask not install');

        this.provider = new BrowserProvider(window.ethereum);

        this.signer = await this.provider.getSigner();
    }

    @toggle('uploading')
    signMessage(message: string) {
        return this.signer.signMessage(message);
    }

    @action
    @toggle('downloading')
    async getAddress() {
        const eth_address = await this.signer.getAddress();

        return (this.address = localStorage.eth_address = eth_address);
    }
}

export default new EthersModel();
