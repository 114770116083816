.rounded-box {
    padding: 4.375rem 0px 0px 0px;
    gap: 2.5rem;
    border-radius: 61px 61px 0px 0px;
    background: #fff;
}

.section-header {
    font-feature-settings:
        'clig' off,
        'liga' off;
    text-shadow: 0px 4px 24.6px rgba(255, 255, 255, 0.39);
    font-size: 3rem;
    line-height: 5.125rem; /* 170.833% */
    letter-spacing: 1.92px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey-a);
    padding: 0 9.375rem;
}

.list-box {
    @media (min-width: 1200px) {
        padding-right: 5%;
        justify-content: end;
        width: 75%;
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
}
