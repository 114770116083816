import { Icon } from 'idea-react';
import { FC, PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';

import * as style from './OverviewSet.module.less';

export type OverviewDataProps = PropsWithChildren<
    Partial<Record<'title' | 'data' | 'icon', string>>
>;

export const OverviewData: FC<OverviewDataProps> = ({ title, data }) => (
    <li className="list-unstyled d-flex flex-column gap-3 align-items-center justify-content-center p-3">
        <em className={`p-3 ${style['gradient-data']}`}>{data}</em>
        <span className="text-white">{title}</span>
    </li>
);

export const OverviewCard: FC<OverviewDataProps> = ({ title, children }) => (
    <Card
        as="li"
        className={`p-4 d-flex flex-column gap-2 font-poppins ${style['overview-card']}`}
    >
        <Card.Title
            as="h4"
            className={`${style['overview-title']} fs-18 text-capitalize fw-500`}
        >
            {title}
        </Card.Title>
        <Card.Text className={`fs-14 fw-500 ${style['overview-body']}`}>
            {children}
        </Card.Text>
    </Card>
);

export const SectionCard: FC<OverviewDataProps> = ({
    icon,
    title,
    children
}) => (
    <Card
        as="li"
        className="d-flex flex-column gap-4 align-items-start text-black px-3 py-0 border border-top-0 mb-0"
    >
        <Card.Title className={`${style['section-title']} pt-4 m-0`}>
            <Icon name={icon} className="fs-23" />
            <h4 className="fs-23 font-poppins fw-500 text-capitalize mb-0 text-break">
                {title}
            </h4>
        </Card.Title>
        <Card.Text className={`font-space pb-5 ${style['section-body']}`}>
            {children}
        </Card.Text>
    </Card>
);

export const ContentItem: FC<OverviewDataProps> = ({ title, children }) => (
    <>
        <dt
            className={`${style['dt-title']} px-3 fs-18 text-capitalize fw-500 text-capitalize`}
        >
            {title}
        </dt>
        <dd className={`px-3 ${style['dd-body']} pb-5`}>{children}</dd>
    </>
);
