.zkvrf-header {
    color: #fff;
    font-feature-settings:
        'clig' off,
        'liga' off;
    text-shadow: 0px 2px 3.2px rgba(255, 255, 255, 0.21) !important;
    font-family: 'Monument Extended';
    font-size: 4.25rem;
    line-height: 83px; /* 122.059% */
    letter-spacing: 11.56px;
    backdrop-filter: blur(5.050000190734863px);

    @media (max-width: 576px) {
        font-size: 1.5rem;
        line-height: 3rem;
        padding-top: 7rem;
    }
}
