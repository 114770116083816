@media (min-width: 1200px) {
    .full-scroll {
        scroll-snap-type: y mandatory;
        background-size: contain;
    }
}

.full-video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}

.video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh + 19px);
    z-index: -8;
    background: linear-gradient(
        to top,
        var(--black-a) 0%,
        rgba(0, 0, 0, 0) 96.45%
    ) !important;
}
